<template>
  <div class="container-page">
    <p class="title-inner">Отчет о фактическом экспорте услуг/товаров Российской Федерации</p>
    <ForeignTradeTurnoverWorldTable :nn="nn" v-if="canView" />
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';

  import ForeignTradeTurnoverWorldTable from '@/modules/importanalytics/components/ForeignTradeTurnoverWorldTable';

  export default {
    name: 'ForeignTradeTurnoverWorldView',
    components: { ForeignTradeTurnoverWorldTable },
    mixins: [security, filtermanager],
    data() {
      return {
        canView: false,
        nn: 1,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.FreeTradeAgreement).then((response) => {
        this.canView = response.data;
        if (!this.canView) {
          this.notAccess();
        }
      });
    },
    methods: {},
    computed: {},
  };
</script>

<style lang="scss"></style>
